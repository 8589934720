import { DOCUMENT } from '@angular/common'
import { Component, effect, inject } from '@angular/core'
import { RouterOutlet } from '@angular/router'

import { TranslocoService } from '@ngneat/transloco'

import { LoadingBarComponent } from '@libs/ng-shared/components/loading-bar'

import { AppStore } from '#core/store/app.store'
import { APP_DB } from '#shared/services/rxdb/db.base'
import { ProjectCollectionService } from '#shared/services/rxdb/project-collection.service'

@Component({
  standalone: true,
  imports: [RouterOutlet, LoadingBarComponent],
  selector: 'ace-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  // Dependencies inject
  doc = inject(DOCUMENT)
  appStore = inject(AppStore)
  translocoService = inject(TranslocoService)
  // db = inject(APP_DB)

  // private variables
  mode: 'dark' | 'light' = 'dark'
  theme: 'default' | 'wps' = 'default' // will be updated later based on environments
  lang: 'en' | 'zh' = 'zh'

  constructor() {
    effect(() => {
      this.mode = this.appStore.styles.mode()
      this.theme = this.appStore.styles.theme()
      this.lang = this.appStore.language()

      // update the mode
      this.updateMode()

      // update the theme
      this.updateTheme()

      // update the language
      this.updateLang()
    })
  }

  // Update theme mode, 'dark' or 'light'
  private updateMode(): void {
    // Remove class names for all theme mode
    this.doc.body.classList.remove('light', 'dark')

    // Add class name for the currently selected theme mode
    this.doc.body.classList.add(this.mode)
  }

  // Update theme, 'default' or others
  private updateTheme(): void {
    // Find the class name for the previously selected theme and remove it
    this.doc.body.classList.forEach(className => {
      if (className.startsWith('theme-')) {
        this.doc.body.classList.remove(className, className.split('-')[1])
      }
    })

    // Add class name for the currently selected theme
    this.doc.body.classList.add(`theme-${this.theme}`)
  }

  // Update language, 'zh' for default
  private updateLang(): void {
    this.translocoService.setActiveLang(this.lang)
  }
}
